import React, { useState, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
  Backdrop,
} from "@material-ui/core";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import * as ROUTES from "../../../../constants/routes";
import { addRefAT, onScroll } from "../../../../store/actions";

import SquareButton from "../../Comms/Buttons/Square";
import UserHelper from "../../Comms/UserHelper";
import TransactionCard from "./components/TransactionCard";

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: theme.palette.primary.main,
    height: "100svh",
    borderRadius: "40px 40px 0 0",
    padding: "1em 2em",
  },
  carteiraButton: {
    cursor: "pointer",
    display: "flex",
  },
  userCredits: {
    color: theme.palette.secondary.grey,
    marginRight: ".2rem",
  },
  ulnp: {
    listStyleType: "none",
  },
  tab: {
    color: "#FFFFFF",
    fontSize: 17,
    fontWeight: "inherit",
    minWidth: "inherit",
    [theme.breakpoints.down("sm")]: {
      fontSize: "inherit",
    },
  },
  buttonsCarteira: {
    marginTop: "1.5em",
  },
  transactions: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  gridContainer: {
    height: "calc(100svh - 253px)",
    overflowY: "auto",
    padding: "0 .5rem",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  gridItem: {
    width: "100%",
  },
  dayText: {
    fontWeight: "bold",
    fontSize: 20,
    margin: "1.7rem 0 1rem 0",
  },
  shadow: {
    boxShadow: `0px 20px 1rem -16px ${theme.palette.secondary.grey}`,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const handleScroll = (e) => {
    const position = e.target.scrollTop;
    dispatch(onScroll(position, "wallet", index));
  };
  const pagesScroll = useSelector((state) => state.pagesScroll);
  const { scroll } = pagesScroll.length
    ? pagesScroll.find((ps) => ps.id === "wallet" && ps.index === index)
    : {};
  const ref = useRef();
  useLayoutEffect(() => {
    if (value === index) {
      ref.current.scrollTop = scroll;
    }
  }, [value]);

  return (
    <Typography
      className={classes.transactions}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`swipeable-tabpanel-${index}`}
      aria-labelledby={`swipeable-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          wrap="nowrap"
          className={classes.gridContainer}
          onScroll={handleScroll}
          ref={ref}
        >
          {children}
        </Grid>
      )}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `swipeable-tab-${index}`,
    "aria-controls": `swipeable-tabpanel-${index}`,
  };
}

const Wallet = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [drawer, setDrawerOpen] = useState(false);

  const handleDrawer = (open) => (e) => {
    setDrawerOpen(open);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const user = useSelector((state) => state.user);
  const allTransactions = useSelector((state) => state.userTransactions);
  const pagesScroll = useSelector((state) => state.pagesScroll);
  const { scroll } = pagesScroll.length
    ? pagesScroll.find((ps) => ps.id === "wallet" && ps.index === value)
    : {};

  var requests = allTransactions.filter(
    (transaction) => transaction.type === `request`
  );

  var payments = allTransactions.filter(
    (transaction) => transaction.type === `payment`
  );

  const groupByDay = (array) => {
    // this gives an object with dates as keys
    let groups = array.reduce((groups, transaction) => {
      const date = transaction.date.toDate();
      if (!groups[date]) {
        groups[date] = [];
      }
      const transactions = array.filter(
        (t) =>
          t.date.toDate().getFullYear() === date.getFullYear() &&
          t.date.toDate().getMonth() === date.getMonth() &&
          t.date.toDate().getDate() === date.getDate()
      );
      groups[date].push(transactions);
      return groups;
    }, {});

    var previousDate;
    // Edit: to add it in the array format instead
    let groupArrays = Object.keys(groups).map((date) => {
      if (
        new Date(date).getFullYear() === new Date(previousDate).getFullYear() &&
        new Date(date).getMonth() === new Date(previousDate).getMonth() &&
        new Date(date).getDate() === new Date(previousDate).getDate()
      ) {
        return null;
      } else {
        previousDate = date;
        return {
          date,
          transactions: groups[date],
        };
      }
    });

    groupArrays = groupArrays.filter((item) => item !== null);

    return groupArrays.map((transaction, i) => {
      let day;
      var month = [];
      month[0] = "Janeiro";
      month[1] = "Fevereiro";
      month[2] = "Março";
      month[3] = "Abril";
      month[4] = "Maio";
      month[5] = "Junho";
      month[6] = "Julho";
      month[7] = "Agosto";
      month[8] = "Setembro";
      month[9] = "Outubro";
      month[10] = "Novembro";
      month[11] = "Dezembro";
      const currentDate = new Date();
      const transactionDate = new Date(transaction.date);
      if (
        transactionDate.getFullYear() === currentDate.getFullYear() &&
        transactionDate.getMonth() === currentDate.getMonth() &&
        transactionDate.getDate() === currentDate.getDate()
      ) {
        day = "Hoje";
      } else {
        day = `${transactionDate.getDate()} ${
          month[transactionDate.getMonth()]
        } ${transactionDate.getFullYear()}`;
      }
      return (
        <div className={classes.gridItem} key={i}>
          <Typography color="secondary" className={classes.dayText}>
            {day}
          </Typography>
          <TransactionCard
            transactions={transaction.transactions[0]}
            handleCloseDrawer={() => setDrawerOpen(false)}
          />
        </div>
      );
    });
  };

  // TUTORIAL IMPLEMENTATION
  const activeTutorial = useSelector((state) => state.activeTutorial);
  const walletButtonRef = useRef();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (activeTutorial && walletButtonRef.current) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
        "header.wallet_button"
      ) {
        const { width, height, top, left } =
          walletButtonRef.current.getBoundingClientRect();
        const ref = { width, height, top, left };

        if (!activeTutorial.ref) {
          dispatch(addRefAT(ref));
        }
      }
    }
  }, [activeTutorial, walletButtonRef.current]);

  useLayoutEffect(() => {
    if (activeTutorial) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
          "header.wallet" ||
        activeTutorial.pages[activeTutorial.activeIndex].component ===
          "header.profile_button"
      ) {
        setDrawerOpen(true);
      } else {
        setDrawerOpen(false);
      }
    } else {
      setDrawerOpen(false);
    }
  }, [activeTutorial]);

  return (
    <>
      <Button onClick={handleDrawer(true)} ref={walletButtonRef}>
        <Typography variant="h6" className={classes.userCredits}>
          {user.credits}
        </Typography>
        <MonetizationOnOutlinedIcon color="primary" />
      </Button>
      <Drawer
        anchor="bottom"
        open={drawer}
        className="drawer"
        ModalProps={{
          disableScrollLock: true,
          disableEscapeKeyDown: true,
          hideBackdrop: true,
        }}
        transitionDuration={200}
      >
        <Backdrop open={true} onClick={handleDrawer(false)} />
        <div role="presentation" className={classes.drawer}>
          <Grid
            container
            alignItems="center"
            className={classes.buttonsCarteira}
          >
            <Grid item xs={3}>
              <SquareButton
                isWhiteText={true}
                component="link"
                href={ROUTES.CREDITS}
                onClick={handleDrawer(false)}
                text="Créditos"
                icon={
                  <MonetizationOnOutlinedIcon
                    color="primary"
                    fontSize="large"
                  />
                }
              />
            </Grid>
            <Grid item xs={3}>
              <SquareButton
                isWhiteText={true}
                component="link"
                href={ROUTES.PAYMENT_METHODS}
                onClick={handleDrawer(false)}
                text="Pagamento"
                icon={<PaymentOutlinedIcon color="primary" fontSize="large" />}
              />
            </Grid>
            <Grid item xs={3}>
              <SquareButton
                isWhiteText={true}
                component="link"
                // href={ROUTES.PLANS}
                // onClick={handleDrawer(false)}
                text="Planos"
                icon={<StarBorderIcon color="primary" fontSize="large" />}
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <SquareButton
                isWhiteText={true}
                text="Indicações"
                icon={<GroupAddOutlinedIcon color="primary" fontSize="large" />}
                disabled={true}
                tooltipExists={true}
                tooltipTitle="Em breve"
              />
            </Grid>
          </Grid>
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            className={scroll > 0 ? classes.shadow : null}
          >
            <Tab label="TODOS" {...a11yProps(0)} className={classes.tab} />
            <Tab
              label="REQUISIÇÕES"
              {...a11yProps(1)}
              className={classes.tab}
            />
            <Tab label="CRÉDITOS" {...a11yProps(2)} className={classes.tab} />
          </Tabs>

          <TabPanel value={value} index={0} dir={theme.direction}>
            {allTransactions.length > 0 ? (
              groupByDay(allTransactions)
            ) : (
              <>
                <div className={classes.gridItem}>
                  <UserHelper
                    type="empty"
                    text="Assim que você fizer alguma requisição ou comprar créditos suas transações aparecerão por aqui!"
                    textColor="white"
                  />
                </div>
              </>
            )}
            <div className="end-space"></div>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            {requests.length > 0 ? (
              groupByDay(requests)
            ) : (
              <>
                <div className={classes.gridItem}>
                  <UserHelper
                    type="empty"
                    text="Assim que você fizer alguma requisição suas transações aparecerão por aqui!"
                    textColor="white"
                  />
                </div>
              </>
            )}
            <div className="end-space"></div>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            {payments.length > 0 ? (
              groupByDay(payments)
            ) : (
              <div className={classes.gridItem}>
                <UserHelper
                  type="empty"
                  text="Assim que você comprar créditos suas transações aparecerão por aqui!"
                  textColor="white"
                />
              </div>
            )}
            <div className="end-space"></div>
          </TabPanel>
        </div>
      </Drawer>
    </>
  );
};

export default Wallet;
