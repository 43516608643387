import React, { useState, useContext, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FirebaseContext } from "../../../../firebase";
import * as ROUTES from "../../../../constants/routes";
import { history } from "../../../../store/history";
import { addRefAT, setErrorAlert } from "../../../../store/actions";

import {
  makeStyles,
  Grid,
  Drawer,
  Avatar,
  Typography,
  IconButton,
  Backdrop,
} from "@material-ui/core";

import iconPerfil from "../../../../Images/perfil.svg";
import SociiPlusStartups from "../../../../Images/socii-plus-startups.svg";

import DefautButton from "../../Comms/Buttons/Default";
import ConfirmChangePasswordPopup from "../../Comms/Dialogs/ConfirmChangePassword";
import DefaultButton from "../../Comms/Buttons/Default";
import { Edit } from "@material-ui/icons";
import { deleteObject, ref, uploadBytes } from "firebase/storage";

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: theme.palette.primary.main,
    height: "100svh",
    borderRadius: "40px 40px 0 0",
    overflow: "hidden",
  },
  profileContainer: {
    padding: "0 3rem",
    paddingTop: "3rem",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0 2rem",
      paddingTop: "2rem",
    },
  },
  profileButton: {
    cursor: "pointer",
  },
  displayName: {
    fontSize: 28,
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  data: {
    fontSize: 18,
    color: theme.palette.secondary.main,
  },
  defaultBtnMarginTop: { marginTop: "1rem" },
  iconButton: {
    position: "relative",
  },
  plusIcon: {
    position: "absolute",
    top: 8,
    right: 5,
    width: "auto",
  },
  editProfile: {
    position: "absolute",
    top: 14,
    right: 23,
    [theme.breakpoints.down("sm")]: {
      top: 18,
    },
  },
  editProfileIcon: {
    fontSize: "1.75rem",
  },
}));

const Profile = () => {
  const classes = useStyles();

  const user = useSelector((state) => state.user);
  const { api, auth, storage } = useContext(FirebaseContext);

  const [open, setOpen] = useState(false);
  const handleOpenDrawer = () => {
    setOpen(true);
  };
  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const [openChangePass, setOpenChangePass] = useState(false);
  const handleOpenChangePassword = () => {
    setOpenChangePass(true);
  };
  const handleCloseChangePass = () => {
    setOpenChangePass(false);
  };

  const handleSignOut = () => {
    return api.signOut().catch(function (error) {
      console.error(error);
      dispatch(setErrorAlert(true));
      api.saveError(error);
    });
  };

  const handleSignIn = () => {
    window.sessionStorage.setItem("pathToRedirect", window.location.pathname);

    return api.signOut().then(() => {
      history.push(ROUTES.SIGN_IN);
    });
  };
  const handleSignUp = () => {
    window.sessionStorage.setItem("pathToRedirect", window.location.pathname);

    return api.signOut().then(() => {
      history.push(ROUTES.SIGN_UP);
    });
  };

  const handleClickEdit = (e) => {
    e.stopPropagation();
  };

  const handleChangeInput = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 1000000) {
      alert("O tamanho máximo do arquivo é de 1MB");
      return;
    }

    if (user.picturePath) {
      // delete old avatar
      const oldPictureRef = ref(storage, `clients_picture/${user.picturePath}`);
      await deleteObject(oldPictureRef);
    }

    const newPictureRef = ref(
      storage,
      `clients_picture/${user.uid}/${file.name}`
    );

    uploadBytes(newPictureRef, file).then((snapshot) => {
      api.updateUser({ picturePath: user.uid + "/" + file.name });
    });
  };

  // TUTORIAL IMPL.
  const activeTutorial = useSelector((state) => state.activeTutorial);
  const profileButtonRef = useRef();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (activeTutorial && profileButtonRef.current) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
        "header.profile_button"
      ) {
        const { width, height, top, left } =
          profileButtonRef.current.getBoundingClientRect();
        const ref = { width, height, top, left };

        if (!activeTutorial.ref) {
          dispatch(addRefAT(ref));
        }
      }
    }
  }, [activeTutorial, profileButtonRef.current]);

  useLayoutEffect(() => {
    if (activeTutorial) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
        "header.profile"
      ) {
        handleOpenDrawer();
      } else {
        handleCloseDrawer();
      }
    } else {
      handleCloseDrawer();
    }
  }, [activeTutorial]);

  return (
    <>
      <IconButton
        onClick={handleOpenDrawer}
        ref={profileButtonRef}
        className={classes.iconButton}
      >
        <Avatar
          id="avatar"
          alt="Ícone perfil"
          src={user.avatar || iconPerfil}
          variant="rounded"
        />
        {user.package && user.package.type === "startup" ? (
          <img
            src={SociiPlusStartups}
            alt="plus icon"
            className={classes.plusIcon}
          />
        ) : null}
      </IconButton>
      <Drawer
        anchor="bottom"
        open={open}
        className="drawer"
        ModalProps={{
          disableScrollLock: true,
          disableEscapeKeyDown: true,
          hideBackdrop: true,
        }}
        transitionDuration={200}
      >
        <Backdrop open={true} onClick={handleCloseDrawer}>
          {!user.isAnon ? (
            <div onClick={handleClickEdit}>
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                id="edit-profile-input"
                onChange={handleChangeInput}
                style={{ display: "none" }}
              />
              <label htmlFor="edit-profile-input">
                <IconButton className={classes.editProfile} component="span">
                  <Edit color="secondary" className={classes.editProfileIcon} />
                </IconButton>
              </label>
            </div>
          ) : null}
        </Backdrop>

        <div role="presentation" className={classes.drawer}>
          <Grid
            container
            spacing={2}
            alignContent="flex-start"
            className={classes.profileContainer}
          >
            <Grid item xs={12}>
              <Typography className={classes.displayName}>
                {user.isAnon ? "Convidado" : user.displayName}
              </Typography>
            </Grid>
            {!user.isAnon ? (
              <>
                <Grid item xs={12}>
                  <Typography className={classes.data}>{user.email}</Typography>
                </Grid>
                {user.phoneNormalized ? (
                  <Grid item xs={12}>
                    <Typography className={classes.data}>
                      ({user.phoneNormalized.slice(0, 2)}){" "}
                      {user.phoneNormalized.slice(2)}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <DefautButton
                    variant="outlined"
                    onClick={handleOpenChangePassword}
                    color="secondary"
                    text={"Alterar senha"}
                    className={classes.defaultBtnMarginTop}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <DefaultButton
                    variant="outlined"
                    onClick={handleSignIn}
                    color="secondary"
                    text="Entrar"
                  />
                </Grid>
                <Grid item xs={12}>
                  <DefaultButton
                    variant="outlined"
                    onClick={handleSignUp}
                    color="secondary"
                    text="Registrar"
                  />
                </Grid>
              </>
            )}
            <ConfirmChangePasswordPopup
              state={openChangePass}
              onClose={handleCloseChangePass}
              handleCloseDrawer={handleCloseDrawer}
              userEmail={user.email}
              userProviderData={
                auth.currentUser ? auth.currentUser.providerData : []
              }
            />
            <Grid item xs={12}>
              <DefautButton
                variant="contained"
                onClick={handleSignOut}
                color="secondary"
                text="Sair"
              />
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </>
  );
};

export default Profile;
