export const SET_USER = `SET_USER`;
export const SET_SERVICES_LIST = `SET_SERVICES_LIST`;
export const SET_EXAMPLE_CARDS = `SET_EXAMPLE_CARDS`;
export const SET_USER_CARDS = `SET_USER_CARDS`;
export const SET_USER_TRANSACTIONS = `SET_USER_TRANSACTIONS`;
export const SIGN_OUT = `SIGN_OUT`;
export const SET_EMAIL_TO_VERIFY = `SET_EMAIL_TO_VERIFY`;
export const SET_AGREE_POPUP = `SET_AGREE_POPUP`;
export const TOGGLE_AGREE_POPUP = `TOGGLE_AGREE_POPUP`;
export const SET_OPEN_ADD_SIGN = `SET_OPEN_ADD_SIGN`;
export const SET_VERIFY_EMAIL_POPUP = `SET_VERIFY_EMAIL_POPUP`;
export const SET_AUTOMATION_FILL = `SET_AUTOMATION_FILL`;
export const SET_PART_COLORS = `SET_PART_COLORS`;
export const SET_SIGNATURE_SESSION = `SET_SIGNATURE_SESSION`;
export const SET_SIGNATURE_CREATION = `SET_SIGNATURE_CREATION`;
export const ADD_PART_SC = `ADD_PART_SC`;
export const EDIT_PART_SC = `EDIT_PART_SC`;
export const REMOVE_PART_SC = `REMOVE_PART_SC`;
export const ADD_FIELD_SC = `ADD_FIELD_SC`;
export const EDIT_FIELD_SC = `EDIT_FIELD_SC`;
export const REMOVE_FIELD_SC = `REMOVE_FIELD_SC`;
export const ADD_PART_RELATED_SC = `ADD_PART_RELATED_SC`;
export const REMOVE_PART_RELATED_SC = `REMOVE_PART_RELATED_SC`;
export const ADD_PART_TO_RELATE_SC = `ADD_PART_TO_RELATE_SC`;
export const REMOVE_PART_TO_RELATE_SC = `REMOVE_PART_TO_RELATE_SC`;
export const ADD_PART_RELATED_TO_RELATE_SC = `ADD_PART_RELATED_TO_RELATE_SC`;
export const SET_DATA_LIST = `SET_DATA_LIST`;
export const SET_TUTORIALS = `SET_TUTORIALS`;
export const SET_ACTIVE_TUTORIAL = `SET_ACTIVE_TUTORIAL`;
export const ADD_REF_AT = `ADD_REF_AT`;
export const SET_FIELD_ANSWER_AF = `SET_FIELD_ANSWER_AF`;
export const SET_PARTS_TO_SHARE = `SET_PARTS_TO_SHARE`;
export const ADD_PART_TO_SHARE = `ADD_PART_TO_SHARE`;
export const REMOVE_PART_TO_SHARE = `REMOVE_PART_TO_SHARE`;
export const CHANGE_PART_TO_SHARE_FIELD = `CHANGE_PART_TO_SHARE_FIELD`;
export const SW_UPDATE = `SW_UPDATE`;
export const SET_PAGES_SCROLL = `SET_PAGES_SCROLL`;
export const ON_SCROLL = `ON_SCROLL`;
export const SET_CHANGE_PAY_POPUP = `SET_CHANGE_PAY_POPUP`;
export const SET_PAGES_INDEX = `SET_PAGES_INDEX`;
export const CHANGE_PAGE_INDEX = `CHANGE_PAGE_INDEX`;
export const SET_ERROR_ALERT = `SET_ERROR_ALERT`;
export const SET_MENTORING_LEVELS = `SET_MENTORING_LEVELS`;
export const SET_MENTORING_LESSONS = `SET_MENTORING_LESSONS`;
export const SET_MENTORING_BADGES = `SET_MENTORING_BADGES`;
export const SET_MENTORING_BENEFITS = `SET_MENTORING_BENEFITS`;
export const SET_GAMIFICATION = `SET_GAMIFICATION`;
export const ADD_POPUP_GAMIFICATION = `ADD_POPUP_GAMIFICATION`;
export const REMOVE_POPUP_GAMIFICATION = `REMOVE_POPUP_GAMIFICATION`;
export const SET_NAVBAR_BACKGROUND = `SET_NAVBAR_BACKGROUND`;
export const SET_ACTIVATED_CODE_POPUP = `SET_ACTIVATED_CODE_POPUP`;
export const SET_OPEN_CONFETTI = `SET_OPEN_CONFETTI`;
export const SET_SUGGEST_SIGN_IN_POPUP = `SET_SUGGEST_SIGN_IN_POPUP`;
export const SET_CUSTOM_AUTOMATIONS = `SET_CUSTOM_AUTOMATIONS`;
export const SET_CUSTOM_SERVICES = `SET_CUSTOM_SERVICES`;
export const SET_CREDITS_PAGE = `SET_CREDITS_PAGE`;
export const RESET_CREDITS_PAGE = `RESET_CREDITS_PAGE`;
export const SET_CREDIT_SELECTED = `SET_CREDIT_SELECTED`;
export const SET_CREDIT_PAYMENT_METHOD = `SET_CREDIT_PAYMENT_METHOD`;
export const SET_CREDIT_INSTALLMENTS = `SET_CREDIT_INSTALLMENTS`;
export const SET_PAYMENT_PAGE = `SET_PAYMENT_PAGE`;
export const RESET_PAYMENT_PAGE = `RESET_PAYMENT_PAGE`;
export const SET_PAY_PAYMENT_METHOD = `SET_PAY_PAYMENT_METHOD`;
export const SET_PAYMENT_INSTALLMENTS = `SET_PAYMENT_INSTALLMENTS`;
export const SET_PLANS_PAGE = `SET_PLANS_PAGE`;
export const RESET_PLANS_PAGE = `RESET_PLANS_PAGE`;
export const SET_PLAN_SELECTED = `SET_PLAN_SELECTED`;
export const SET_PLAN_PAYMENT_METHOD = `SET_PLAN_PAYMENT_METHOD`;
export const SET_PLAN_INSTALLMENTS = `SET_PLAN_INSTALLMENTS`;
export const SET_SETTING_SIGN = `SET_SETTING_SIGN`;
export const SET_PART_SYNCED_ON_SETTING_SIGN = `SET_PART_SYNCED_ON_SETTING_SIGN`;
export const RESET_SETTING_SIGN = `RESET_SETTING_SIGN`;
export const SET_ACCEPT_TERMS_DIALOG = `SET_ACCEPT_TERMS_DIALOG`;
export const SET_IS_LOADING_PART = `SET_IS_LOADING_PART`;
export const SET_IS_ADDING_PART = `SET_IS_ADDING_PART`;
export const SET_SELECTED_PAYMENT_METHOD = `SET_SELECTED_PAYMENT_METHOD`;

export const signOut = () => ({
  type: SIGN_OUT,
});

export const setUser = (user) => ({
  type: SET_USER,
  user,
});

export const setServicesList = (servicesList) => ({
  type: SET_SERVICES_LIST,
  servicesList,
});

export const setSignatureSession = (signatureSession) => ({
  type: SET_SIGNATURE_SESSION,
  signatureSession,
});

export const setExampleCards = (exampleCards) => ({
  type: SET_EXAMPLE_CARDS,
  exampleCards,
});

export const setPartColors = (partColors) => ({
  type: SET_PART_COLORS,
  partColors,
});

export const setUserCards = (userCards) => ({
  type: SET_USER_CARDS,
  userCards,
});

export const setUserTransactions = (userTransactions) => ({
  type: SET_USER_TRANSACTIONS,
  userTransactions,
});

export const setEmailToVerify = (email) => ({
  type: SET_EMAIL_TO_VERIFY,
  email,
});

export const setAgreePopup = (state) => ({
  type: SET_AGREE_POPUP,
  state,
});

export const toggleAgreePopup = () => ({
  type: TOGGLE_AGREE_POPUP,
});

export const setOpenAddSign = (state) => ({
  type: SET_OPEN_ADD_SIGN,
  state,
});

export const setVerifyEmailPopup = (state) => ({
  type: SET_VERIFY_EMAIL_POPUP,
  state,
});

export const setAutomationFill = (state) => ({
  type: SET_AUTOMATION_FILL,
  state,
});

export const setFieldAnswerAF = (activePart, activeStep, field, value) => ({
  type: SET_FIELD_ANSWER_AF,
  activePart,
  activeStep,
  field,
  value,
});

export const setSignatureCreation = (object) => ({
  type: SET_SIGNATURE_CREATION,
  object,
});

export const addPartSC = (part) => ({
  type: ADD_PART_SC,
  part,
});

export const editPartSC = (part) => ({
  type: EDIT_PART_SC,
  part,
});

export const removePartSC = (id) => ({
  type: REMOVE_PART_SC,
  id,
});

export const addFieldSC = (field) => ({
  type: ADD_FIELD_SC,
  field,
});

export const editFieldSC = (field) => ({
  type: EDIT_FIELD_SC,
  field,
});

export const removeFieldSC = (id) => ({
  type: REMOVE_FIELD_SC,
  id,
});

export const addPartRelatedSC = (id, partRelated) => ({
  type: ADD_PART_RELATED_SC,
  id,
  partRelated,
});

export const removePartRelatedSC = (id) => ({
  type: REMOVE_PART_RELATED_SC,
  id,
});

export const addPartToRelateSC = (partToRelate) => ({
  type: ADD_PART_TO_RELATE_SC,
  partToRelate,
});

export const removePartToRelateSC = () => ({
  type: REMOVE_PART_TO_RELATE_SC,
});

export const addPartRelatedToRelateSC = (partRelated) => ({
  type: ADD_PART_RELATED_TO_RELATE_SC,
  partRelated,
});

export const setDataList = (dataList) => ({
  type: SET_DATA_LIST,
  dataList,
});

export const setTutorials = (tutorials) => ({
  type: SET_TUTORIALS,
  tutorials,
});

export const setActiveTutorial = (activeTutorial) => ({
  type: SET_ACTIVE_TUTORIAL,
  activeTutorial,
});

export const addRefAT = (ref) => ({
  type: ADD_REF_AT,
  ref,
});

export const setPartsToShare = (parts) => ({
  type: SET_PARTS_TO_SHARE,
  parts,
});

export const addPartToShare = (part) => ({
  type: ADD_PART_TO_SHARE,
  part,
});

export const removePartToShare = (partType) => ({
  type: REMOVE_PART_TO_SHARE,
  partType,
});

export const changePartToShareField = (partType, field, value) => ({
  type: CHANGE_PART_TO_SHARE_FIELD,
  partType,
  field,
  value,
});

export const swUpdate = (payload) => ({
  type: SW_UPDATE,
  payload,
});

export const setPagesScroll = (array) => ({
  type: SET_PAGES_SCROLL,
  array,
});

export const onScroll = (scroll, id, index) => ({
  type: ON_SCROLL,
  scroll,
  id,
  index,
});

export const setPagesIndex = (array) => ({
  type: SET_PAGES_INDEX,
  array,
});

export const changePageIndex = (id, index) => ({
  type: CHANGE_PAGE_INDEX,
  id,
  index,
});

export const setChangePayPopup = (state) => ({
  type: SET_CHANGE_PAY_POPUP,
  state,
});

export const setNavbarBackground = (state) => ({
  type: SET_NAVBAR_BACKGROUND,
  state,
});

export const setErrorAlert = (state) => ({
  type: SET_ERROR_ALERT,
  state,
});

export const setMentoringLevels = (array) => ({
  type: SET_MENTORING_LEVELS,
  array,
});

export const setMentoringLessons = (array) => ({
  type: SET_MENTORING_LESSONS,
  array,
});

export const setMentoringBadges = (array) => ({
  type: SET_MENTORING_BADGES,
  array,
});

export const setMentoringBenefits = (array) => ({
  type: SET_MENTORING_BENEFITS,
  array,
});

export const setGamification = (array) => ({
  type: SET_GAMIFICATION,
  array,
});

export const addPopup = (object) => ({
  type: ADD_POPUP_GAMIFICATION,
  object,
});

export const removePopup = (name) => ({
  type: REMOVE_POPUP_GAMIFICATION,
  name,
});

export const setActivatedCodePopup = (state) => ({
  type: SET_ACTIVATED_CODE_POPUP,
  state,
});

export const setOpenConfetti = (state) => ({
  type: SET_OPEN_CONFETTI,
  state,
});

export const setSuggestSignInPopup = (state) => ({
  type: SET_SUGGEST_SIGN_IN_POPUP,
  state,
});

export const setCustomAutomations = (state) => ({
  type: SET_CUSTOM_AUTOMATIONS,
  state,
});

export const setCustomServices = (state) => ({
  type: SET_CUSTOM_SERVICES,
  state,
});

export const setCreditsPage = (state) => ({
  type: SET_CREDITS_PAGE,
  state,
});

export const setCreditSelected = (credit) => ({
  type: SET_CREDIT_SELECTED,
  credit,
});

export const resetCreditsPage = () => ({
  type: RESET_CREDITS_PAGE,
});

export const setSelectedPaymentMethod = (paymentMethod) => ({
  type: SET_SELECTED_PAYMENT_METHOD,
  paymentMethod,
});

export const setCreditPaymentMethod = (paymentMethod) => ({
  type: SET_CREDIT_PAYMENT_METHOD,
  paymentMethod,
});

export const setCreditInstallments = (installments) => ({
  type: SET_CREDIT_INSTALLMENTS,
  installments,
});

export const setPaymentPage = (state) => ({
  type: SET_PAYMENT_PAGE,
  state,
});

export const resetPaymentPage = () => ({
  type: RESET_PAYMENT_PAGE,
});

export const setPayPaymentMethod = (paymentMethod) => ({
  type: SET_PAY_PAYMENT_METHOD,
  paymentMethod,
});

export const setPaymentInstallments = (installments) => ({
  type: SET_PAYMENT_INSTALLMENTS,
  installments,
});

export const setPlansPage = (state) => ({
  type: SET_PLANS_PAGE,
  state,
});

export const resetPlansPage = () => ({
  type: RESET_PLANS_PAGE,
});

export const setPlanSelected = (plan) => ({
  type: SET_PLAN_SELECTED,
  plan,
});

export const setPlanPaymentMethod = (paymentMethod) => ({
  type: SET_PLAN_PAYMENT_METHOD,
  paymentMethod,
});

export const setPlanInstallments = (installments) => ({
  type: SET_PLAN_INSTALLMENTS,
  installments,
});

export const setSettingSign = (state) => ({
  type: SET_SETTING_SIGN,
  state,
});

export const setPartSyncedOnSettingSign = (state) => ({
  type: SET_PART_SYNCED_ON_SETTING_SIGN,
  state,
});

export const resetSettingSign = () => ({
  type: RESET_SETTING_SIGN,
});

export const setAcceptTermsDialog = (state) => ({
  type: SET_ACCEPT_TERMS_DIALOG,
  state,
});

export const setIsLoadingPart = (state) => ({
  type: SET_IS_LOADING_PART,
  state,
});

export const setIsAddingPart = (state) => ({
  type: SET_IS_ADDING_PART,
  state,
});
