import React from "react";
import { Typography, makeStyles, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../store/history";
import { resetSettingSign } from "../../../store/actions";
import { uuidv4 } from "@firebase/util";
import SquareButton from "../../Components/Comms/Buttons/Square";
import { EditOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.secondary.darkGrey,
  },
}));

export default function TestPage() {
  const classes = useStyles();
  const theme = useTheme();
  const userCards = useSelector((state) => state.userCards);
  const dispatch = useDispatch();

  const handleRedirectSign2 = () => {
    const locationSplitted = window.location.pathname.split("/");
    if (locationSplitted[1] === "configurar-assinatura") return;

    dispatch(resetSettingSign());
    history.push(`/configurar-assinatura/${uuidv4()}`);
  };
  return (
    <div className="feed-grid">
      <div style={{ padding: "1.5rem" }}>
        <Typography
          variant="h4"
          align="center"
          className={classes.title}
          gutterBottom
        >
          Teste nossa nova ferramenta de assinatura de documentos
        </Typography>

        <div style={{ padding: "1rem" }} />

        <SquareButton
          icon={
            <EditOutlined color="secondary" style={{ fontSize: "1.8rem" }} />
          }
          text="Assinatura 2.0"
          onClick={handleRedirectSign2}
          gradient={`linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`}
        />
      </div>
    </div>
  );
}
