import {
  Dialog,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FirebaseContext } from "../../../../firebase";
import { setErrorAlert } from "../../../../store/actions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DefaultButton from "../Buttons/Default";
import SignOutlinedIcon from "../../../../Images/sign-outlined.svg";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    padding: "2rem",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem",
    },
  },
  text: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.secondary.grey,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  link: {
    textDecoration: "underline",
    fontWeight: 900,
    color: theme.palette.secondary.grey,
  },
}));

export default function AcceptTermsDialog() {
  const classes = useStyles();

  const { api } = useContext(FirebaseContext);

  const dispatch = useDispatch();
  const acceptTermsDialog = useSelector((state) => state.acceptTermsDialog);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.updateUser({ notAcceptedTerms: false });
    } catch (error) {
      console.error(error);
      dispatch(setErrorAlert(true));
      api.saveError(error);
    }
  };

  return (
    <Dialog open={acceptTermsDialog} className={`dialog agree-popup`}>
      <Grid
        className={classes.root}
        container
        spacing={2}
        alignContent="center"
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid item xs={12} container justifyContent="center">
          <img src={SignOutlinedIcon} alt="Sign Outlined Icon" width={150} />
        </Grid>
        <Grid item xs={12} className={classes.text}>
          <Typography variant="h6" className={classes.text}>
            Ao utilizar a plataforma você aceita os{" "}
            <Link to="/termos-de-uso-introducao" className={classes.link}>
              termos de uso
            </Link>{" "}
            e{" "}
            <Link
              to="/politica-de-privacidade-introducao"
              className={classes.link}
            >
              política de privacidade
            </Link>
            .
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <DefaultButton
            type="submit"
            variant="contained"
            color="primary"
            text="Vamos lá!"
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
