import React, { useEffect, useState } from "react";
import SquareButton from "../Comms/Buttons/Square";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { history } from "../../../store/history";
import {
  Dialog,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DefaultButton from "../Comms/Buttons/Default";
import { useDispatch, useSelector } from "react-redux";
import { AssignmentOutlined } from "@material-ui/icons";
import MailIcon from "../../../Images/mail.svg";
import InvalidIcon from "../../../Images/invalid.svg";
import ConfirmationIcon from "../../../Images/sociiConfirmation.svg";

import {
  setSuggestSignInPopup,
  setVerifyEmailPopup,
} from "../../../store/actions";
import { pdfjs } from "react-pdf";
import LoadingComponent from "../Comms/Loading";

// The workerSrc property shall be specified.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2rem",
    borderRadius: 29,
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem",
    },
  },
  textOrange: {
    color: theme.palette.primary.main,
  },
  icon: {
    [theme.breakpoints.down("xs")]: {
      width: 130,
    },
  },
  text: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  titleReadingDoc: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.secondary.grey,
  },
  paperReadingDoc: {
    width: 350,
  },
}));

export default function SummarizeDoc() {
  // MUI
  const classes = useStyles();
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const theme = useTheme();
  // REDUX
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // STATES
  const [document, setDocument] = useState({});
  const [creditsCost, setCreditsCost] = useState(0);
  const [isReadingDoc, setIsReadingDoc] = useState(false);
  const [estimatedDate, setEstimatedDate] = useState(null);
  const [diffSecs, setDiffSecs] = useState(0);
  // VARIABLES
  const isDisabled = diffSecs !== 0;
  const diffMinutes = isDisabled ? Math.floor(diffSecs / 60) : null;

  // POPUPS
  const [openSentMail, setOpenSentMail] = useState(false);
  const [openConfirmCredits, setOpenConfirmCredits] = useState(false);
  const [openNoCredits, setOpenNoCredits] = useState(false);
  const [openCannotSummarize, setOpenCannotSummarize] = useState(false);
  // EFFECTS
  useEffect(() => {
    const estimatedDateLS = window.localStorage.getItem("estimatedDate");

    if (!estimatedDate) {
      if (estimatedDateLS) {
        setEstimatedDate(new Date(estimatedDateLS));
      } else return;
    }

    const interval = setInterval(() => {
      const time = Date.parse(estimatedDate) - Date.now();
      if (time <= 0) {
        setEstimatedDate(null);
        setDiffSecs(0);
        window.localStorage.removeItem("estimatedDate");
        return;
      }
      setDiffSecs(Math.floor(time / 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, [estimatedDate]);
  // HANDLERS
  const handleCloseSentMail = () => {
    setOpenSentMail(false);
  };
  const handleCloseConfirmCredits = () => {
    setOpenConfirmCredits(false);
  };
  const handleCloseNoCredits = () => {
    setOpenNoCredits(false);
  };
  const handleCloseCannotSummarize = () => {
    setOpenCannotSummarize(false);
  };
  const handleRedirectToCredits = () => {
    setOpenNoCredits(false);
    history.push(`/creditos/${creditsCost - user.credits}`);
  };
  const handleChangeInput = (e) => {
    if (user.isAnon) {
      dispatch(setSuggestSignInPopup(true));
      return;
    }
    if (!user.emailVerified) {
      dispatch(setVerifyEmailPopup(true));
      return;
    }

    if (isDisabled) return;

    const file = e.target.files[0];
    if (!file) return;
    e.target.value = "";

    const isPDF = file.type === "application/pdf";

    setIsReadingDoc(true);
    setTimeout(() => {
      const reader = new FileReader();
      reader.onload = () => {
        verifyCreditsToSummarize(reader.result, file.name, isPDF);
      };
      reader.onerror = () => {
        console.log("Error: ", reader.error);
      };
      reader.readAsBinaryString(file);
    }, 1000);
  };
  const handleConfirmSummarize = () => {
    summarizeDoc();
    setOpenConfirmCredits(false);
    setOpenSentMail(true);

    // get estimated time in seconds
    const timeInSecs = document.estimatedTime * 60 || 60;

    const startDate = new Date();
    // add estimated time to current date
    const estimate = startDate;
    estimate.setSeconds(startDate.getSeconds() + timeInSecs);

    setEstimatedDate(estimate);
    // save estimated date in local storage
    window.localStorage.setItem("estimatedDate", estimate);
  };
  // FUNCTIONS
  const extractTextFromPDF = async (content) => {
    let fullText = "";
    var loadingTask = pdfjs.getDocument({ data: content });
    await loadingTask.promise.then(
      async function (pdf) {
        const { numPages } = pdf;
        for (var i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i);
          const text = await page.getTextContent();
          text.items.map((item) => {
            fullText += item.str + " ";
          });
        }
      },
      function (reason) {
        console.error(reason);
      }
    );
    return fullText;
  };
  const verifyCreditsToSummarize = async (content, title, isPDF) => {
    let fullText;
    if (isPDF) {
      fullText = await extractTextFromPDF(content);
    } else {
      var zip = new PizZip(content);
      var doc = new Docxtemplater().loadZip(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      fullText = doc.getFullText();
    }

    if (fullText.length === 0) {
      setIsReadingDoc(false);
      setOpenCannotSummarize(true);
      return;
    }

    const creditsCost = parseInt(fullText.length / 8000) || 1;

    setCreditsCost(creditsCost);

    let estimatedTime = parseInt(fullText.length / 9000);

    if (estimatedTime >= 16) estimatedTime = 15;

    setIsReadingDoc(false);
    if (creditsCost > user.credits) {
      setOpenNoCredits(true);
    } else {
      setOpenConfirmCredits(true);
      setDocument({
        fullText,
        title,
        estimatedTime,
      });
    }
  };
  const summarizeDoc = () => {
    fetch("https://openai-summarize-6mjsaxzria-uc.a.run.app", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        document,
        uid: user.uid,
      }),
    }).then((result) => {
      const status = result.status;
      console.log("status: ", status);
      if (status === 500 || status === 400) {
        window.localStorage.removeItem("estimatedDate");
        setEstimatedDate(null);
      }
    });
  };

  return (
    <>
      <input
        type="file"
        accept=".docx, .pdf"
        id="summarize-doc-input"
        onChange={handleChangeInput}
        style={{ display: "none" }}
        disabled={isDisabled}
      />
      <label htmlFor="summarize-doc-input">
        <SquareButton
          icon={
            <AssignmentOutlined
              color="primary"
              style={{ fontSize: "1.8rem" }}
            />
          }
          text="Resumo I.A."
          component="span"
          disabled={isDisabled}
          tooltipExists={isDisabled}
          tooltipTitle={`Aguarde ${
            diffMinutes > 1
              ? `${diffMinutes} minutos`
              : `${diffSecs} segundo${diffSecs > 1 ? "s" : ""}`
          } para resumir outro documento`}
          placement="top"
        />
      </label>
      <Dialog
        open={openCannotSummarize}
        PaperProps={{
          elevation: 1,
        }}
        classes={{
          paper: classes.paper,
        }}
        onClose={handleCloseCannotSummarize}
      >
        <Grid container spacing={matchesXs ? 3 : 4} justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <img src={InvalidIcon} width={120} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Ops...
            </Typography>
            <Typography variant="body1">
              Identificamos que este documento não possui texto ou o texto
              presente foi aplicado em formato de imagem. Por favor, selecione
              outro documento.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DefaultButton
              variant="contained"
              color="primary"
              text="Entendi"
              onClick={handleCloseCannotSummarize}
            />
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={openSentMail}
        PaperProps={{
          elevation: 1,
        }}
        classes={{
          paper: classes.paper,
        }}
        onClose={handleCloseSentMail}
      >
        <Grid container spacing={matchesXs ? 3 : 4} justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <img src={MailIcon} width={matchesXs ? 150 : 200} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">
              Estamos resumindo seu documento...
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <b>
                Tempo estimado: {document.estimatedTime}~
                {document.estimatedTime + 1} minuto
                {document.estimatedTime === 0 ? "" : "s"}
              </b>
            </Typography>
            <Typography variant="body1">
              Aguarde alguns minutos e atualize seu e-mail, o resumo chegará por
              lá em breve! 
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DefaultButton
              variant="contained"
              color="primary"
              text="Fechar"
              onClick={handleCloseSentMail}
            />
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={openConfirmCredits}
        PaperProps={{
          elevation: 1,
        }}
        classes={{
          paper: classes.paper,
        }}
        onClose={handleCloseConfirmCredits}
      >
        <Grid container spacing={matchesXs ? 3 : 4} justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <img src={ConfirmationIcon} width={180} className={classes.icon} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" align="center" className={classes.text}>
              Você utilizará apenas{" "}
              <b className={classes.textOrange}>
                {creditsCost} crédito{creditsCost > 1 ? "s" : ""}
              </b>{" "}
              para resumir esse documento. Clique em "Resumir" e descubra os
              pontos mais importantes do seu documento!
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={matchesXs ? 1 : 2}>
            <Grid item xs={6}>
              <DefaultButton
                variant="outlined"
                color="primary"
                text="Desistir"
                onClick={handleCloseConfirmCredits}
              />
            </Grid>
            <Grid item xs={6}>
              <DefaultButton
                variant="contained"
                color="primary"
                text="Resumir"
                onClick={handleConfirmSummarize}
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={openNoCredits}
        PaperProps={{
          elevation: 1,
        }}
        classes={{
          paper: classes.paper,
        }}
        onClose={handleCloseNoCredits}
      >
        <Grid container spacing={matchesXs ? 3 : 4} justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <img src={ConfirmationIcon} width={180} className={classes.icon} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" align="center" className={classes.text}>
              Ops... Para prosseguir você precisa adicionar{" "}
              <b className={classes.textOrange}>
                {creditsCost - user.credits} crédito
                {creditsCost - user.credits > 1 ? "s" : ""}
              </b>{" "}
              na sua conta. Clique em "Continuar" para adicioná-los e
              gerar seu resumo.
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={matchesXs ? 1 : 2}>
            <Grid item xs={6}>
              <DefaultButton
                variant="outlined"
                color="primary"
                text="Cancelar"
                onClick={handleCloseNoCredits}
              />
            </Grid>
            <Grid item xs={6}>
              <DefaultButton
                variant="contained"
                color="primary"
                text="Continuar"
                onClick={handleRedirectToCredits}
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={isReadingDoc}
        PaperProps={{
          elevation: 1,
        }}
        classes={{
          paper: `${classes.paper} ${classes.paperReadingDoc}`,
        }}
      >
        <Grid container spacing={4} justifyContent="center">
          <LoadingComponent type="readingDoc" />
        </Grid>
      </Dialog>
    </>
  );
}
