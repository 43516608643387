import React from "react";

import {
  Paper,
  Grid,
  Typography,
  Divider,
  makeStyles,
  Button,
} from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import DateFormat from "../../../../../Helpers/DateFormat";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { history } from "../../../../../store/history";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 25,
    color: theme.palette.secondary.darkGrey,
    backgroundColor: theme.palette.secondary.main,
    margin: ".5em 0",
    padding: ".5em 0",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
    },
  },
  bold: {
    fontWeight: "bold",
  },
  pdgtp: {
    paddingTop: 15,
  },
  pdgbt: {
    paddingBottom: 15,
  },
  bg: {
    width: "3em",
    height: "3em",
    margin: "0 auto",
    borderRadius: 15,
    boxShadow: "inherit",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    padding: ".3em",
    fontSize: ".75rem",
  },
  date: {
    color: theme.palette.secondary.main,
    margin: "1em 0",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  orange: {
    color: theme.palette.primary.main,
  },
  orangePaper: {
    backgroundColor: theme.palette.primary.light,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: "1.8em",
  },
  divider: {
    margin: ".5rem 0",
  },
  gridPadding: {
    padding: ".8rem 1rem",
  },
  darkOrangeColor: {
    backgroundColor: theme.palette.primary.dark,
  },
  greenColor: {
    backgroundColor: theme.palette.primary.done,
  },
  creditsDiv: { display: "flex", justifyContent: "space-between" },
  dividerDisplayNone: { display: "none" },
  credits: {
    marginLeft: ".2rem",
    fontWeight: "bold",
  },
}));

export const TransactionCard = ({ transactions, handleCloseDrawer }) => {
  const classes = useStyles();

  const handlePendingPay = (id) => (e) => {
    history.push(`/informacoes-pagamento-pendente/${id}`);
    handleCloseDrawer();
  };

  return (
    <Paper className={classes.paper}>
      {transactions.map((transaction, i) => (
        <div key={i}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.gridPadding}
          >
            <Grid item xs={2}>
              <Paper
                className={`${classes.bg} ${
                  transaction.type === "request" ||
                  transaction.status === "cancelled"
                    ? classes.darkOrangeColor
                    : transaction.status === "pending"
                    ? classes.orangePaper
                    : classes.greenColor
                }`}
              >
                {transaction.type === "request" ? (
                  <ReceiptIcon className={classes.icon} />
                ) : (
                  <MonetizationOnIcon className={classes.icon} />
                )}
              </Paper>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2" className={classes.bold}>
                {transaction.text}{" "}
                {transaction.status === "pending" && "pendente"}
                {transaction.status === "cancelled" && "cancelada"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              container
              direction="column"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                {transaction.status === "pending" ? (
                  <Button
                    color="primary"
                    onClick={handlePendingPay(transaction.orderId)}
                  >
                    Pagar
                  </Button>
                ) : transaction.status === "cancelled" ? (
                  <s className={classes.creditsDiv}>
                    <Typography variant="body2" className={classes.bold}>
                      {transaction.credits}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="primary"
                      className={classes.credits}
                    >
                      <b>Créditos</b>
                    </Typography>
                  </s>
                ) : transaction.price ? (
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.credits}
                  >
                    {transaction.price}
                  </Typography>
                ) : (
                  <div className={classes.creditsDiv}>
                    <Typography variant="body2" className={classes.bold}>
                      {transaction.type === "request"
                        ? `-${transaction.credits}`
                        : `+${transaction.credits}`}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="primary"
                      className={classes.credits}
                    >
                      Créditos
                    </Typography>
                  </div>
                )}
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <DateFormat
                    date={
                      transaction.status === "cancelled"
                        ? transaction.cancelledAt
                        : transaction.date
                    }
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider
            className={`${classes.divider} ${
              transactions.length === i + 1 ? classes.dividerDisplayNone : null
            }`}
          />
        </div>
      ))}
    </Paper>
  );
};

export default TransactionCard;
