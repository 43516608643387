import React from "react";

import { Dialog, Grid, Typography, makeStyles } from "@material-ui/core";

import DefaultButton from "../Buttons/Default";
import ConfirmationIcon from "../../../../Images/sociiConfirmation.svg";
import {
  CREDITS,
  SIGN_IN,
  SIGN_UP,
  WHATSAPP_PACKAGES,
} from "../../../../constants/routes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setSuggestSignInPopup } from "../../../../store/actions";
import { history } from "../../../../store/history";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.darkGrey,
    backgroundColor: theme.palette.secondary.main,
    position: "relative",
  },
  confirmIcon: {
    width: 140,
    [theme.breakpoints.down("xs")]: {
      width: 120,
    },
  },
  dialogText: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.secondary.darkGrey,
    margin: ".5rem auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      maxWidth: "100%",
    },
  },
}));

export default function SuggestSignInPopup() {
  const classes = useStyles();

  const suggestSignInPopup = useSelector((state) => state.suggestSignInPopup);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setSuggestSignInPopup(false));
  };

  const handleSignIn = () => {
    window.sessionStorage.setItem("pathToRedirect", window.location.pathname);
    dispatch(setSuggestSignInPopup(false));
    history.push(SIGN_IN);
  };
  const handleSignUp = () => {
    window.sessionStorage.setItem("pathToRedirect", window.location.pathname);
    dispatch(setSuggestSignInPopup(false));
    history.push(SIGN_UP);
  };
  return (
    <Dialog
      open={suggestSignInPopup}
      onClose={handleClose}
      className="popup-intern"
    >
      <Grid
        className={classes.root}
        container
        spacing={2}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Typography align="center" className={classes.dialogText}>
            Para realizar esta ação é necessário que você esteja conectado a uma
            conta.
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <DefaultButton
              variant="outlined"
              color="primary"
              text="Entrar"
              onClick={handleSignIn}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">ou</Typography>
          </Grid>
          <Grid item xs={12}>
            <DefaultButton
              variant="contained"
              color="primary"
              text="Registrar"
              onClick={handleSignUp}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
