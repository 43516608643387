import React, { useState, useContext } from "react";
import { history } from "../../../../store/history";
import * as ROUTES from "../../../../constants/routes";
import { FirebaseContext } from "../../../../firebase";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Dialog,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { VisibilityOff, Visibility } from "@material-ui/icons";

import ConfirmationIcon from "../../../../Images/sociiConfirmation.svg";
import DefaultButton from "../Buttons/Default";
import { useDispatch } from "react-redux";
import { setErrorAlert } from "../../../../store/actions";
import { httpsCallable } from "firebase/functions";
import { signInWithEmailAndPassword } from "firebase/auth";

const useStyles = makeStyles((theme) => ({
  dialogGrid: {
    backgroundColor: theme.palette.secondary.main,
    padding: "2em",
    overflow: "hidden",
  },
  dialogText: {
    fontSize: 20,
    fontWeight: 300,
    color: theme.palette.secondary.darkGrey,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  icon: {
    marginBottom: "1.5rem",
  },
  btns: {
    marginTop: "1.5rem",
  },
}));

export default function ConfirmChangePasswordPopup({
  state,
  onClose,
  handleCloseDrawer,
  userEmail,
  userProviderData,
}) {
  const classes = useStyles();

  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  var haveCurrentPass = false;
  userProviderData.forEach((profile) => {
    if (profile.providerId === "password") {
      haveCurrentPass = true;
    }
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [samePassword, setSamePassword] = useState(false);
  const [weakPassword, setWeakPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { functions, api, auth } = useContext(FirebaseContext);
  const updatePassword = httpsCallable(functions, "user-updatePassword");

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isLoading) return;
    setIsLoading(true);

    setWrongPassword(false);
    setSamePassword(false);
    setWeakPassword(false);
    setConfirmPasswordError(false);
    setSamePassword(false);

    if (values.newPassword !== values.confirmNewPassword) {
      setIsLoading(false);
      setConfirmPasswordError(true);
      return;
    } else if (values.newPassword.length < 6) {
      setIsLoading(false);
      setWeakPassword(true);
      return;
    }

    if (!haveCurrentPass) {
      return api
        .linkWithCredential(userEmail, values.newPassword)
        .then(() => {
          history.push(ROUTES.VERIFY_COMPLETE_INTERN_PAGE);
          setIsLoading(false);
          onClose();
          handleCloseDrawer();
        })
        .catch((error) => {
          console.log("Account linking error", error);
          setIsLoading(false);
          dispatch(setErrorAlert(true));
          api.saveError(error);
        });
    } else {
      if (values.oldPassword === values.newPassword) {
        setIsLoading(false);
        setSamePassword(true);
        return;
      }
      return signInWithEmailAndPassword(auth, userEmail, values.oldPassword)
        .then(() => {
          return updatePassword({
            email: userEmail,
            password: values.newPassword,
          })
            .then(() => {
              history.push(ROUTES.VERIFY_COMPLETE_INTERN_PAGE);
              setIsLoading(false);
              onClose();
              handleCloseDrawer();
            })
            .catch((e) => {
              console.error("Error updating password: ", e);
              dispatch(setErrorAlert(true));
              api.saveError(e);

              setIsLoading(false);
            });
        })
        .catch((e) => {
          if (e.code === "auth/wrong-password") {
            setWrongPassword(true);
          } else {
            dispatch(setErrorAlert(true));
            api.saveError(e);
          }
          console.error("Error sign in user", e);
          setIsLoading(false);
        });
    }
  };

  return (
    <Dialog onClose={onClose} open={state} className="dialog">
      <Grid
        className={classes.dialogGrid}
        container
        spacing={2}
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid item xs={12} container justifyContent="center">
          <img
            src={ConfirmationIcon}
            className={classes.icon}
            alt="Ícone de confirmação"
          />
        </Grid>
        {haveCurrentPass && (
          <Grid item xs={12}>
            <TextField
              label="Senha atual"
              type="password"
              fullWidth
              className="default-text-field"
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={values.oldPassword}
              onChange={handleChange("oldPassword")}
              error={wrongPassword}
              helperText={wrongPassword ? "Esta não é sua senha atual." : null}
              required
              autoComplete="current-password"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            id="new-password"
            label="Nova senha"
            type={showPassword ? "text" : "password"}
            fullWidth
            className="default-text-field"
            value={values.password}
            onChange={handleChange("newPassword")}
            error={weakPassword || samePassword}
            helperText={
              weakPassword
                ? "Esta senha está fraca. É necessário usar uma senha com pelo menos 6 caracteres."
                : samePassword
                ? "Esta senha é igual a sua senha atual."
                : null
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    className={`${classes.visibility} btn-visibility`}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            required
            autoComplete="new-password"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="confirm-password"
            label="Confirme sua nova senha"
            type="password"
            value={values.confirmNewPassword}
            error={confirmPasswordError}
            helperText={
              confirmPasswordError ? "As senhas não estão iguais." : null
            }
            onChange={handleChange("confirmNewPassword")}
            fullWidth
            className="default-text-field"
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            required
            autoComplete="new-password"
          />
        </Grid>

        <Grid item xs={6} className={classes.btns}>
          <DefaultButton
            variant="outlined"
            color="primary"
            onClick={onClose}
            text="Voltar"
          />
        </Grid>
        <Grid item xs={6} className={classes.btns}>
          <DefaultButton
            variant="contained"
            color="primary"
            type="submit"
            text={
              isLoading ? <CircularProgress color="secondary" /> : "Confirmar"
            }
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
