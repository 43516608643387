import {
  Checkbox,
  Dialog,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import NewReleasesIcon from "../../../Images/new-releases.svg";
import SignOutlinedIcon from "../../../Images/sign-outlined.svg";
import DefaultButton from "../Comms/Buttons/Default";
import { FirebaseContext } from "../../../firebase";
import * as ROUTES from "../../../constants/routes";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2rem",
    borderRadius: 29,
    overflowX: "hidden",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem",
    },
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  body: {
    [theme.breakpoints.down("xs")]: {
      fontSize: ".75rem",
    },
  },
  checkbox: {
    marginRight: 5,
  },
  linkTermos: {
    color: theme.palette.primary.main,
  },
}));

export default function NewReleases(props) {
  const { user, activeTutorial, toggleDrawer } = props;

  const location = useLocation();

  // FIREBASE
  const { api } = useContext(FirebaseContext);

  // MUI
  const classes = useStyles();
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const matchesDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  // STATES
  const [open, setOpen] = useState(false);

  const [checked, setChecked] = useState(false);

  const releaseId = "termsAndPolicy";

  // EFFECTS
  useEffect(() => {
    if (
      user.uid &&
      !user.isAnon &&
      user.sawIntro &&
      !activeTutorial &&
      (location.pathname === "/" ||
        (releaseId === "termsAndPolicy" &&
          location.pathname !== ROUTES.TERMS_OF_SERVICE &&
          location.pathname !== ROUTES.PRIVACY_POLICE))
    ) {
      if (user.newReleases) {
        // Check if user has already seen this release
        const release = user.newReleases.find(
          (release) => release.id === releaseId
        );
        if (release && release.seen) return;
      }
      const publishDate = new Date("2023-07-28T15:19:00.000Z");

      if (
        releaseId === "termsAndPolicy" &&
        user.signUpDate &&
        user.signUpDate.toDate().getTime() >= publishDate.getTime()
      )
        return;

      setOpen(true);
    }
  }, [user, activeTutorial, location.pathname]);

  // HANDLERS
  const handleClose = () => {
    updateFirestoreField();
    setOpen(false);
  };
  const handleConfirm = () => {
    updateFirestoreField();
    setOpen(false);
    if (!matchesDesktop) {
      toggleDrawer(true);
    }
  };
  const handleOnlyClose = () => {
    setOpen(false);
  };

  // FUNCTIONS
  const updateFirestoreField = () => {
    api.userSawNewRelease({ id: releaseId });
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        elevation: 1,
      }}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} container justifyContent="center">
          <img
            src={releaseId === "signature" ? SignOutlinedIcon : NewReleasesIcon}
            width={matchesXs ? 130 : 200}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.title} gutterBottom>
            {releaseId === "termsAndPolicy" ? (
              <b>Atualização dos Termos de uso e Política do App</b>
            ) : (
              <b>
                Apresentamos a nova ferramenta para{" "}
                {releaseId === "summarize" && "resumir contratos"}
                {releaseId === "signature" && "assinar documentos"}
              </b>
            )}
          </Typography>

          <Typography variant="body2" className={classes.body}>
            {releaseId === "summarize" && (
              <>
                Estamos lançando uma nova ferramenta que utiliza algoritmos
                avançados de processamento de linguagem natural para resumir
                contratos de forma instantânea e fácil de entender. O resumo
                inclui informações sobre os termos, cláusulas importantes,
                responsabilidades e obrigações de cada parte envolvida.
              </>
            )}
            {releaseId === "signature" && (
              <>
                Estamos lançando uma nova ferramenta que permite assinar
                documentos de forma fácil e segura. A assinatura digital é
                equivalente à assinatura manuscrita e tem validade jurídica.
                Teste agora mesmo!
              </>
            )}
            {releaseId === "termsAndPolicy" && (
              <>
                Nossa prioridade sempre foi oferecer a você uma experiência
                excepcional e segura, e é por isso que fizemos algumas melhorias
                em nossa política para garantir sua tranquilidade enquanto
                utiliza nossos serviços. Com total transparência, proteção de
                dados aprimorada e respeito à sua privacidade, nossa política
                agora é mais clara e acessível, reforçando nosso compromisso em
                proteger seus dados e proporcionar a melhor experiência possível
                em nosso app!
              </>
            )}
          </Typography>
        </Grid>
        {releaseId === "termsAndPolicy" && (
          <Grid item xs={12} container alignItems="center" wrap="nowrap">
            <Checkbox
              name="termos"
              color="primary"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              className={classes.checkbox}
              required
            />
            <Typography variant="body2">
              Li e estou de acordo com os{" "}
              <Link
                to={ROUTES.TERMS_OF_SERVICE}
                className={classes.linkTermos}
                onClick={handleOnlyClose}
              >
                termos de uso
              </Link>{" "}
              e{" "}
              <Link
                to={ROUTES.PRIVACY_POLICE}
                className={classes.linkTermos}
                onClick={handleOnlyClose}
              >
                política de privacidade
              </Link>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} container spacing={matchesXs ? 1 : 2}>
          {releaseId === "termsAndPolicy" ? (
            <Grid item xs={12}>
              <DefaultButton
                variant="contained"
                color="primary"
                text="Confirmar"
                disabled={!checked}
                onClick={handleConfirm}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                <DefaultButton
                  variant="outlined"
                  color="primary"
                  text="Fechar"
                  onClick={handleClose}
                />
              </Grid>
              <Grid item xs={6}>
                <DefaultButton
                  variant="contained"
                  color="primary"
                  text="Testar"
                  onClick={handleConfirm}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}
