import React, { useState, useLayoutEffect, useRef } from "react";
import { history } from "../../../../../store/history";
import * as ROUTES from "../../../../../constants/routes";
import {
  setOpenAddSign,
  setSignatureCreation,
  addRefAT,
} from "../../../../../store/actions";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";

import { SwipeableDrawer, makeStyles } from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";

import AutomationIcon from "../../../../../Images/automation.svg";
import FolderIcon from "../../../../../Images/folder.svg";
import DriveIcon from "../../../../../Images/drive.svg";
import ScannerIcon from "../../../../../Images/scanner.svg";
import TouchDrawer from "../../../../../Images/touch_drawer.svg";

import SquareButton from "../../../../Components/Comms/Buttons/Square";
import WarningCantNegotiatePopup from "../dialogs/WarningCantNegotiate";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: theme.palette.primary.main,
    height: 190,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  touch: {
    marginTop: "1rem",
    userSelect: "none",
  },
  btnsList: {
    marginTop: "2.5rem",
    padding: "0 1.5rem",
    width: "100%",
    display: "flex",
    "& .square-btn": {
      margin: "0 .5rem",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      overflowX: "auto",
      padding: "0",
      paddingBottom: "1rem",
      paddingLeft: "1rem",
    },
  },
  inputDisplayNone: { display: "none" },
  descriptionIcon: {
    fontSize: "1.8rem",
  },
}));

const firstPart = {
  id: uuidv4(),
  name: "Você",
  color: "darkOrange",
  active: true,
  willShare: false,
};

const AddSign = (props) => {
  const classes = useStyles();

  const [warningPopup, setWarningPopup] = useState(false);
  const handlePopup = (open) => (e) => {
    setWarningPopup(open);
  };

  const location = useLocation();

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.log("There is no file");
      return;
    }

    let name = file.name.replace(".pdf", "");
    dispatch(
      setSignatureCreation({
        docName: name,
        file,
        parts: [firstPart],
        fields: [],
      })
    );

    const historyObj = {
      pathname: `/definir-assinatura`,
      state: {
        docName: name,
        file,
        parts: [firstPart],
        fields: [],
      },
    };
    if (location.pathname === "/definir-assinatura") {
      history.replace(historyObj);
    } else {
      history.push(historyObj);
    }
    dispatch(setOpenAddSign(false));

    // Erasing input file
    inputFileRef.current.value = "";
  };

  const openAddSign = useSelector((state) => state.openAddSign);
  const activeTutorial = useSelector((state) => state.activeTutorial);

  const toggleDrawer = (newOpen) => () => {
    dispatch(setOpenAddSign(newOpen));
  };

  const dispatch = useDispatch();
  const signButtonsRef = useRef();
  const inputFileRef = useRef();

  useLayoutEffect(() => {
    if (activeTutorial && signButtonsRef.current && openAddSign) {
      if (
        activeTutorial.pages[activeTutorial.activeIndex].component ===
        "signature.addSign_buttons"
      ) {
        setTimeout(() => {
          const { width, height, top, left } =
            signButtonsRef.current.getBoundingClientRect();
          const ref = { width, height, top, left };
          if (!activeTutorial.ref) {
            dispatch(addRefAT(ref));
          }
        }, 300);
      }
    }
  }, [activeTutorial, signButtonsRef.current, openAddSign]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openAddSign}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      className="drawer-intern"
      disableSwipeToOpen
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div role="presentation" className={classes.drawer}>
        <img src={TouchDrawer} alt="Touch" className={classes.touch} />
        <div className={classes.btnsList} ref={signButtonsRef}>
          <SquareButton
            isWhiteText={true}
            icon={
              <DescriptionOutlined
                className={classes.descriptionIcon}
                color="primary"
              />
            }
            text="Histórico"
            component="link"
            href={ROUTES.SELECT_DOCUMENT_TO_SIGN}
            onClick={toggleDrawer(false)}
          />

          <input
            type="file"
            className={classes.inputDisplayNone}
            accept="application/pdf"
            ref={inputFileRef}
            id="select-file"
            onChange={onFileChange}
          />
          <label htmlFor="select-file">
            <SquareButton
              isWhiteText={true}
              icon={<img src={FolderIcon} alt="Dispositivo" />}
              text="Dispositivo"
              component="span"
            />
          </label>
          <SquareButton
            isWhiteText={true}
            icon={<img src={AutomationIcon} alt="Automações" />}
            text="Automações"
            component="link"
            href={ROUTES.SELECT_AUTOMATION_TO_SIGN}
            disabled={true}
          />
          <SquareButton
            isWhiteText={true}
            icon={<img src={DriveIcon} alt="Drive" />}
            text="Drive"
            component="button"
            disabled={true}
          />
          <SquareButton
            isWhiteText={true}
            icon={<img src={ScannerIcon} alt="Scanner" />}
            text="Scanner"
            component="button"
            disabled={true}
          />
        </div>
        <WarningCantNegotiatePopup open={warningPopup} handler={handlePopup} />
      </div>
    </SwipeableDrawer>
  );
};

export default AddSign;
